<section class="header">
  <h1>We Help You Find Your Ideal Next Purchase.</h1>
  <h2>We Got You.</h2>
</section>
<section class="hero">
  <app-prod-details singleProduct="fcf" [feature]="true" [showPriceTag]="true"></app-prod-details>
</section>
<section class="usps">
  <h2>Why You Should Use Find Cars Faster</h2>
  <div class="uspsContainer">
    <div class="usp">
      <img ngSrc="/assets/icons/sand-clock.svg" height="50" width="50" alt="Save time">
      <h3>Save Time</h3>
      <div class="usp-content">
      <p>1 hour per car. Searching UK. Comparing. Calculating. STOP!</p>
      <p>We got sick of searching sites endlessly. Double checking Retail prices. Switching sites. Figuring out all
        costs. Losing hours upon hours at REAL personal cost.</p>
      <p>TheCarSite makes finding cars a dream.</p>
      <p>It’s Your Time. It’s Your Life. There’ Are Better Ways to Spend It.</p>
      </div>
    </div>
    <div class="usp">
      <img ngSrc="/assets/icons/turbo-engine.svg" height="50" width="50" alt="Turbo">
      <h3>Turbo Charge Profits</h3>
      <div class="usp-content">
      <p>You pick the budget. We find the cars with the highest ROI that sell the FASTEST. We could say more but we will
        let the product do the talking.</p>
      <p>TheCarSite is built by dealers for dealers.</p>
      <p>Choose to Live More. Choose TheCarSite.</p>
      </div>
    </div>
    <div class="usp">
      <img ngSrc="/assets/icons/alert.svg" height="50" width="50" alt="Alerts">
      <h3>Profitable Cars Alerts</h3>
      <div class="usp-content">
      <p>Let us send suggested cars to you in real time. Stop scrolling through crap and scrap. Stop comparing. Stop
        calculating.</p>
      <p>TheCarSite will send you the cars you need to see.</p>
      <p>Search, Scroll, Compare, Wilt or Use TheCarSite. Your Decision.</p>
      </div>
    </div>
  </div>
  <button class="c2aLarge" mat-raised-button color="primary" role="link" routerLink="/register">Get Started</button>
</section>
<section class="reviews">
  <h2>What Our Customers Just Like You Say</h2>
  <app-reviews></app-reviews>
</section>
<section class="valueProp">
  <h2>What You Get From Find Cars Faster</h2>
  <div class="valueProps">
    <div>
      <img ngSrc="/assets/icons/relax.svg" width="16" height="16" alt="Time">
      <p>Time. Time. Time. Time.</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/customer-service.svg" width="16" height="16" alt="Ideal cars">
      <p>The Ideal Cars presented to You</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/money.svg" width="16" height="16" alt="Money">
      <p>Projected Profit</p>
    </div>
    <div>
      <div class="img">ROI</div>
      <p>Projected ROI</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/money.svg" width="16" height="16" alt="Money">
      <p>Total Cost to Land</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/stopwatch.svg" width="16" height="16" alt="Stopwatch">
      <p>Average Time to Sell</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/zoom.svg" width="16" height="16" alt="Magnifying glass">
      <p>‘Funny Stuff’ Flagged (Before History Checks)</p>
    </div>
    <div>
      <div class="img">VRT</div>
      <p>VRT Calculated for You</p>
    </div>
    <div>
      <div class="img">VAT</div>
      <p>VAT Calculated for You</p>
    </div>
    <div>
      <div class="img">NOx</div>
      <p>NOx Calculated for You</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/currencies.svg" width="16" height="16" alt="Currency">
      <p>Forex Calculated for You</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/ship.svg" width="16" height="16" alt="Ship">
      <p>Shipping Calculated for You</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/cars.svg" width="16" height="16" alt="Cars">
      <p>Browse cars using dealer specific functionality</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/zoom.svg" width="16" height="16" alt="Search">
      <p>Search based on your criteria</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/alert.svg" width="16" height="16" alt="Alert">
      <p>Alerts sent for your ideal cars</p>
    </div>
    <div>
      <img ngSrc="/assets/icons/handshake.svg" width="16" height="16" alt="Alert">
      <p>Importing Cars Made Easy</p>
    </div>
  </div>
  <button class="c2aLarge" mat-raised-button role="link" routerLink="/register">Get Started</button>
</section>
<section class="stats">
  <h2>Why You Need Find Cars Faster</h2>
  <app-statbits sort="fcf"></app-statbits>
  <button class="c2aLarge" mat-raised-button role="link" color="primary" routerLink="/register">Get Started</button>
</section>
<section class="hero prods">
  <div class="prods">
    <app-products exclude="fcf"></app-products>
  </div>
</section>
