<footer>
  <section class="queries">
    <div>
      <h2>Queries?</h2>
      <p>We're here to help you!</p>
    </div>
    <button mat-raised-button routerLink="/contact" role="link">Contact</button>
  </section>
  <section class="details">
    <div class="about">
      <h3>About us</h3>
      <p><span>One Partner. Every Solution.</span>
        <span>One Fair Fee. All in One Place.</span></p>
      @if(userService.isLoggedIn()) {
        <button mat-raised-button routerLink="/home/account" role="link" color="primary">My Account</button>
      } @else {
      <button mat-raised-button routerLink="/login" role="link" color="primary">Login</button>
      }
    </div>
    <div class="contact">
      <h3>Contact us</h3>
      <p>We're here to help you - get in touch any time.</p>
      <ul>
        <li><img ngSrc="/assets/icons/smartphone-call.svg" width="16" height="16" alt="Phone"> <span>Call
            @switch (localeService.locale) {
              @case ('en-GB') {
                 <a href="tel:+442038070547">+44 20 3807 0547</a>
              }
              @case ('en-IE') {
                <a href="tel:+35319125453">+353 1 912 5453</a>
              }
            }
          </span></li>
        <li><img ngSrc="/assets/icons/mail.svg" width="16" height="16" alt="Phone"> <span>E-mail <a href="mailto:support@thecarsite.com">{{ 'support@thecarsite.com' }}</a></span></li>
        <li><img ngSrc="/assets/icons/comment.svg" width="16" height="16" alt="Chat"> <a role="button" class="clickable" onclick="$zoho.salesiq.floatwindow.visible('show')">Chat with us</a></li>
      </ul>
    </div>
    <div class="nav">
      <h3>Navigate</h3>
      <nav>
        <a routerLink="/">Home</a>
        <a routerLink="/ccq" [hidden]="!localeService.showService('ccq')">Calculate Cars Quicker</a>
        <a routerLink="/cyc" [hidden]="!localeService.showService('cyc')">Check Your Car</a>
        <a routerLink="/fcf" [hidden]="!localeService.showService('fcf')">Find Cars Faster</a>
        <a routerLink="/ffl" [hidden]="!localeService.showService('ffl')">Forex For Less</a>
        <a routerLink="/tcspro" [hidden]="!localeService.showService('tcspro')">TheCarSite Pro</a>
        <a routerLink="/contact">Contact us</a>
        <a routerLink="/about">About us</a>
      </nav>
    </div>
  </section>
  <section class="payments">
    <p>All payments are securely handled by <img ngSrc="/assets/payment/stripe.svg" height="32" width="48" alt="Stripe"></p>
    <div class="paymentMethods">
      <img ngSrc="/assets/payment/visa.svg" height="16" width="24" alt="VISA">
      <img ngSrc="/assets/payment/mastercard.svg" height="16" width="24" alt="Mastercard">
      <img ngSrc="/assets/payment/amex.svg" height="16" width="24" alt="AMEX">
      <img ngSrc="/assets/payment/diners.svg" height="16" width="24" alt="Diners">
      <img ngSrc="/assets/payment/jcb.svg" height="16" width="24" alt="JCB">
      <img ngSrc="/assets/payment/unionpay.svg" height="16" width="24" alt="Union Pay">
    </div>
  </section>
  <section class="trust">
    <div class="trustmarks">
<!--      <div class="trustedsite-trustmark" data-type="216" data-width="160" data-height="50"></div>-->
      <div class="trustedsite-trustmark" data-type="202" data-width="120" data-height="50"></div>
      <div class="google-ssl prodCard"><img ngSrc="/assets/providers/google.svg" width="16" height="16" alt="Google"> SSL Verified by Google Trust Services</div>
      <div class="google-ssl prodCard"><img ngSrc="/assets/providers/firebase.svg" width="16" height="16" alt="Firebase"> Firebase Authentication</div>
    </div>
  </section>
  <section class="copyright">
    <p>© The Car Site Limited 2024. All rights reserved.</p>
    <a routerLink="/privacy">Privacy</a>
    <a routerLink="/terms">Terms</a>
  </section>
</footer>
