import {Component, EventEmitter, Inject, OnInit} from "@angular/core";
import {CurrencyPipe, formatCurrency, formatNumber, KeyValuePipe, NgIf} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatSelect} from "@angular/material/select";
import { FormGroup, ReactiveFormsModule} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatSlider, MatSliderRangeThumb} from "@angular/material/slider";
import {MatInput} from "@angular/material/input";
import {MatTooltip} from "@angular/material/tooltip";
import {MatIcon} from "@angular/material/icon";
import {MatSnackBar} from "@angular/material/snack-bar";
import {BodyTypePipe} from "../../../pipes/body-type.pipe";
import {ApiService} from "../../../services/api.service";
import {FcfService} from "../../../services/fcf.service";

@Component({
  selector: "app-fcf-filters",
  standalone: true,
  imports: [
    KeyValuePipe,
    MatButton,
    MatFormField,
    MatLabel,
    MatOption,
    MatProgressSpinner,
    MatSelect,
    NgIf,
    ReactiveFormsModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatSlider,
    MatSliderRangeThumb,
    CurrencyPipe,
    BodyTypePipe,
    MatInput,
    MatTooltip,
    MatIcon
  ],
  templateUrl: "./fcf-filters.component.html",
  styleUrl: "./fcf-filters.component.scss",
})
export class FcfFiltersComponent implements OnInit {
  public submit = new EventEmitter<FormGroup>();
  public reset = new EventEmitter<FormGroup>();
  public savedNotification = new EventEmitter();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { filtersFormGroup: FormGroup, mode: "fcf" | "notifications" },
    private dialog: MatDialog,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    protected fcfService: FcfService,
  ) {}
  ngOnInit() {
    this.data.filtersFormGroup.valueChanges.subscribe(values => {
      if (values.makes?.length === 0) {
        // this.modelsFilter.disabled = true;
        this.data.filtersFormGroup.get("models")?.disable({ onlySelf: true, emitEvent: false });
      } else {
        // this.modelsFilter.disabled = false;
        this.data.filtersFormGroup.get("models")?.enable({ onlySelf: true, emitEvent: false });
      }
      // this.getData(this.paginator?.pageSize, 1)
      //   .catch(e => console.error(e));
    });
  }

  protected applyFilters() {
    this.submit.emit(this.data.filtersFormGroup);
    this.close();
  }

  protected resetFilters() {
    this.reset.emit(this.data.filtersFormGroup);
  }

  protected close() {
    this.dialog.getDialogById("fcf-filters")?.close();
  }

  protected formatPrice(value: number): string {
    return formatCurrency(value, "en", "€", "EUR", "1.0-0");
  }
  protected formatEngineSize(value: number): string {
    const num = formatNumber(value, "en", "1.0-0");
    return `${num}cc`;
  }
  protected formatKms(value: number): string {
    const num = formatNumber(value, "en", "1.0-0");
    return `${num}km`;
  }
  protected formatPercent(value: number): string {
    const num = formatNumber(value * 100, "en", "1.0-0");
    return `${num}%`;
  }
  protected formatGPerKm(value: number): string {
    const num = formatNumber(value, "en", "1.0-0");
    return `${num}`;
  }

  protected async createNotification() {
    const r = await this.apiService.saveNotifications(this.data.filtersFormGroup.value, this.data.filtersFormGroup.get("id")?.value)
      .catch(e => console.error(e));
    if (r?.success) {
      this.snackBar.open("Notification saved", undefined, {panelClass: "snack-ok", duration: 5000});
    } else {
      this.snackBar.open(`Could not save the notification. ${r?.msg}`, undefined, {panelClass: "snack-error", duration: 5000});
    }
    this.savedNotification.emit();
    this.close();
  }
}
