import { Component } from '@angular/core';
import {MatButton} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {NgOptimizedImage} from "@angular/common";
import {LocaleService} from "../../services/locale.service";
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    MatButton,
    RouterLink,
    NgOptimizedImage
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  constructor(protected localeService: LocaleService, protected userService: UserService) {
  }
}
