import {Component, Inject} from "@angular/core";
import {DecimalPipe, NgClass, NgIf, NgTemplateOutlet} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {CommerceService} from "../../services/commerce.service";
import {RouterLink} from "@angular/router";
import {ApiService} from "../../services/api.service";
import {MatCheckbox} from "@angular/material/checkbox";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import {LocaleService} from "../../services/locale.service";

@Component({
  selector: "app-choose-product",
  standalone: true,
  imports: [DecimalPipe, MatButton, MatProgressSpinner, RouterLink, NgClass, MatCheckbox, NgIf, NgTemplateOutlet],
  templateUrl: "./choose-product.component.html",
  styleUrl: "./choose-product.component.scss",
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChooseProductComponent {
  protected paymentInitiated: boolean = false;
  constructor(
    public commerceService: CommerceService,
    private apiService: ApiService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    protected localeService: LocaleService
  ) {}

  async pay() {
    this.paymentInitiated = true;
    const res = await this.apiService.initiatePaymentSession(this.payload);
    if (res?.success) {
      // Save the payment id in localstorage
      this.storage.set("session_id", res.data.id);
      // Redirect to provided link
      window.location = res.data.location;
    }
  }
  get total(): number {
    let total = 0;
    for (let product of this.commerceService.selectedProducts) {
      total += Number(product.price);
    }
    return total;
  }
  get payload(): any {
    const prods = [];
    if (this.commerceService.products) {
      for (let product of this.commerceService.selectedProducts) {
        prods.push({
          price: product.default_price,
          quantity: 1,
        });
      }
    }
    return { line_items: prods, mode: 'subscription' };
  }
}
