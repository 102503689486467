<section class="header">
  <h1>Register with TheCarSite</h1>
</section>
<section class="registerForm">
  <div class="registerForm">
<!--    <div class="providers">-->
<!--      <button mat-raised-button (click)="register('google')">-->
<!--        <div class="buttonContent">-->
<!--          <img ngSrc="/assets/providers/google.svg" alt="Google" height="21" width="21">-->
<!--          Sign in with Google-->
<!--        </div>-->
<!--      </button>-->
<!--      <button mat-raised-button (click)="register('ms')">-->
<!--        <div class="buttonContent">-->
<!--          <img ngSrc="/assets/providers/ms.svg" alt="Microsoft" width="21" height="21">-->
<!--          Sign in with Microsoft-->
<!--        </div>-->
<!--      </button>-->
<!--    </div>-->
<!--    <div class="or">or</div>-->
    <form [formGroup]="registerForm" class="signupForm">
      <mat-form-field appearance="outline">
        <mat-label>E-mail</mat-label>
        <input matInput type="text" formControlName="email">
        <mat-error>Please input a valid e-mail</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="pwd1">
        <mat-error>Password must be at least 5 characters</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Repeat password</mat-label>
        <input matInput type="password" formControlName="pwd2">
        <mat-error>Please repeat the same password</mat-error>
      </mat-form-field>
    </form>
    <div class="actions">
      <ngx-turnstile class="middle" [siteKey]="'0x4AAAAAAAeTJ1vuEprBwZKB'" (resolved)="sendCaptchaResponse($event)" theme="light" [tabIndex]="0"></ngx-turnstile>
      <div class="middle trustedsite-trustmark" data-type="211" data-width="120" data-height="50"></div>
      <button class="next" [disabled]="!registerForm.touched || registerForm.invalid" mat-raised-button color="primary"
              (click)="register('pwd')">Next
      </button>
    </div>
  </div>
</section>
